<template>
  <div class="main">
    <heads></heads>
    <banners></banners>

    <div class="cons">
      <div class="cons-tjcs">匹配词数：{{subcynum}}</div>
      <el-table :data="sclist" style="width: 100%" v-loading="loading">
        <el-table-column prop="kw" label="关键字" />
        <el-table-column prop="tf" sortable label="词频" />
        <el-table-column prop="tfdouble" sortable label="词频/总词数" />
        <el-table-column prop="idf" sortable label="idf" />
        <el-table-column prop="tfidf" sortable label="tfidf" />
        <!-- <el-table-column prop="tfxcbl" sortable label="tfxcbl" /> -->
        <el-table-column prop="old_tf" sortable label="分类号中tf"/>
        <el-table-column prop="old_tfdouble" sortable label="分类号中tfdouble"/>
        <el-table-column prop="old_idf" sortable label="分类号中idf"/>
        <el-table-column prop="old_tfidf" sortable label="分类号中tfidf"/>
        <el-table-column prop="xishu" sortable label="系数" />
        <!-- <el-table-column prop="yxresult" sortable label="系数"/> -->
      </el-table>
    </div>

    <footers></footers>
  </div>
</template>

<script setup>
import { reactive, ref, getCurrentInstance } from "vue";
import { ElMessage } from "element-plus";
import { get } from "@/utils/index";
import { useRouter } from "vue-router";
import { constant } from "lodash";
const router = useRouter();
const { proxy } = getCurrentInstance();

// 中图分类号数据
let sclist=reactive([])
let subcynum=ref(0)

testLog();
// 获取数据  暂时未使用
function testLog() {
  get("/wang/api/Lw/SubZldjProcess", {
    id: router.currentRoute.value.query.id,
    dj:router.currentRoute.value.query.flh,
  }).then((res) => {
    // console.log(res);
    sclist.push(...res.data)
    subcynum.value=sclist[0].subcynum
  });
}

</script>

<style scoped>
.main {
  background: #fff;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 5px 0 50px 0;
  font-size: 14px;
}
.ztflhtitle-list{
  background: #f2f2f2;
  padding: 10px 20px;
  margin: 10px 0;
}
.cons-tjcs{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  background: #fafafa;
  font-size: 14px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
</style>
